import React from "react";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

import Navbar from "../navbar/Navbar";
import ContactUs from "../contactUs/ContactUs";
import Custom from "./research-config";
import "./Research.css";
import Footer from "../footer/Footer";

class Research extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        //Sanitise to stop XSS attacks, must be in form of object with __html as key
        const description = {__html: DOMPurify.sanitize(Custom.description)};
        let paperDescription;

        return (
            <>
                <Helmet>
                    <title>Research and Publications</title>
                    <meta name="description" content="Learn about studies published by our talented team of psychologists including topics such as borderline personality disorder, depression, anxiety and how personality traits can impact treatment and outcomes." />
                </Helmet>

                <Navbar {...this.props} page="studies"/>
                <div className="titleBgImg">
                    <div className="titleBanner">
                        <div className="titleContent">
                            <h1>{Custom.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div dangerouslySetInnerHTML={description} className="adolescentsContent"></div>
                    <div className="parentsInfo">
                        <h4>{Custom.subtitle}</h4>
                        {Custom.studies.map((study, i) => {
                            return(
                                <div key={i}>
                                    <h5>{study.individual}</h5>
                                    <ol>
                                    {study.papers.map((study, i) => {
                                        paperDescription = {__html: DOMPurify.sanitize(study)};
                                        return(
                                        <li key={i}>
                                            <div dangerouslySetInnerHTML={paperDescription}/>
                                        </li>
                                        );
                                    })}
                                    </ol>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <ContactUs/>
                <Footer/>
            </>
        );
    }
}

export default Research;