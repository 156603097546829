import React from "react";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";

import Navbar from "../navbar/Navbar";
import ContactUs from "../contactUs/ContactUs";
import Custom from "./bookings-config";
import "./Bookings.css";
import Footer from "../footer/Footer";

class Bookings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        //Sanitise to stop XSS attacks, must be in form of object with __html as key
        const description = {__html: DOMPurify.sanitize(Custom.description)};
        const sectionDescription = {__html: DOMPurify.sanitize(Custom.section1.description)};
        const buttonDescription = {__html: DOMPurify.sanitize(Custom.button.description)};

        const navigateToBooking = () => window.open("https://www.halaxy.com/profile/talia-hashmani-hashworth-psychology/psychologist/684911");
        const navigateToFaq = () => {
            this.props.history.push("/faqs");
            window.scrollTo(0, 0);
        }

        return (
            <>
                <Helmet>
                    <title>Booking an Appointment</title>
                    <meta name="description" content="Want to discuss how we can support your needs? Book now to speak to one of our psychologists and begin or continue your journey fort personal growth and recovery." />
                </Helmet>

                <Navbar {...this.props} page="bookings"/>
                <div className="titleBgImg">
                    <div className="titleBanner">
                        <div className="titleContent">
                            <h1>{Custom.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="bookingContent">
                    <div className="equalContentBox">
                        <div className="equalSection">
                            <h4>{Custom.section1.heading}</h4>
                            <div dangerouslySetInnerHTML={sectionDescription}></div>
                        </div>
                        <div className="equalSection">
                            <h4>{Custom.section2.heading}</h4>
                            <p>{Custom.section2.description}</p>
                        </div>
                    </div>
                    <div className="bookingCTA">
                        <Button className="clickableBtn bookingBtn" size="lg" variant="primary" onClick={navigateToBooking}>
                            {Custom.button.title}
                        </Button>
                        <div className="bookingCTADesc" dangerouslySetInnerHTML={buttonDescription}></div>
                    </div>
                    <div className="feesContent">
                        <div dangerouslySetInnerHTML={description}></div>
                    </div>
                    <div className="faqCTA">
                        <h5 className="faqDescription">{Custom.faqButton.description}</h5>
                        <Button className="clickableBtn bookingBtn" size="lg" variant="primary" onClick={navigateToFaq}>
                            {Custom.faqButton.title}
                        </Button>
                    </div>
                </div>
                <ContactUs/>
                <Footer/>
            </>
        );
    }
}

export default withRouter(Bookings);