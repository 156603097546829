export default {
    title: "Contact Hashworth Psychology Pty Ltd",
    description: "We’d love to talk to you. Feel free to contact us using the details below or send us an online enquiry to see if Hashworth Psychology is the best fit for you.",
    leftDetails: [
        {
            heading: "Email",
            img: "",
            link: "mailto:connect@hashworthpsychology.com.au",
            description: "connect@hashworthpsychology.com.au"
        },
        {
            heading: "Mobile",
            img: "",
            link: "tel:0491-683-910",
            description: "0491 683 910"
        },
        {
            heading: "Fax",
            img: "",
            link: "tel:02-4044-0152",
            description: "02 4044 0152"
        },
        {
            heading: "Facebook",
            img: "",
            link: "https://www.facebook.com/hashworthpsychology",
            description: "https://www.facebook.com/hashworthpsychology"
        },
        {
            heading: "Instagram",
            img: "",
            link: "https://www.instagram.com/hashworthpsychology",
            description: "https://www.instagram.com/hashworthpsychology"
        }
    ],
    rightDetails: [
        {
            heading: "Clinic Address",
            img: "",
            link: "",
            description: "1A Young Street, Wollongong, NSW, 2500"
        },
        {
            heading: "Parking",
            img: "",
            link: "",
            description: "There are three free patient parking spots at the front of the property that can be used during your sessions. If these spots happen to be full, there are plenty of 2-hour spots around the surrounding streets. Alternatively, there is ample paid parking at Wollongong Central, which is minutes away."
        }
    ],
    button: {
        title: "Submit an enquiry form",
        link: "/enquiries"
    }
}