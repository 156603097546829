import taliaProfile from "../../img/staff/talia-profile.JPG";
import caitlinProfile from "../../img/staff/caitlin-profile.jpg";
import juliaProfile from "../../img/staff/julia-profile.png";
import alanaProfile from "../../img/staff/alana-profile.png";
import taniaProfile from "../../img/staff/tania-profile.png";
import salaProfile from "../../img/staff/sala-profile.jpg";
import oliviaProfile from "../../img/staff/olivia-profile.jpg";
import marcusProfile from "../../img/staff/marcus-profile.jpg";
import nicoleProfile from "../../img/staff/nicole-profile.jpg";
import jasminProfile from "../../img/staff/jasmin-profile.jpg";
import madelineProfile from "../../img/staff/madeline-profile.jpg";
import elizabethProfile from "../../img/staff/elizabeth-profile.jpg";
import lukeProfile from "../../img/staff/luke-profile.jpeg";
import rachelProfile from "../../img/staff/rachel-profile.jpg";
import KatProfile from "../../img/staff/kat-profile.jpg";

export default {
    title: "About Us",
    description: "We’re dedicated to create a space for your recovery and development. We believe that you are the expert of your own experiences and our role is to encourage you to see yourself as the brave, courageous, and tenacious individual that we see you as.",
    socialMediaText: `
        <p><i>Follow our <a href="https://www.facebook.com/hashworthpsychology">Facebook</a> and <a href="https://www.instagram.com/hashworthpsychology">Instagram</a> pages to keep up to date with Hashworth Psychology Pty Ltd posts!</i></p>
    `,
    staffHeader: "Our team",
    clinicalStaff: {
        header: "Clinical",
        staff: [
            {
                img: taliaProfile,
                heading: "Dr Talia Hashworth",
                title: " Principal Clinical Psychologist/Director",
                pronouns: "She/Her",
                researchButton: true,
                description: `
                    <p>Dr Talia Hashworth is the founder of Hashworth Psychology Pty Ltd, a unique mental health service in the Wollongong CBD. Dr Hashworth completed both a Master of Clinical Psychology degree and a Doctor of Philosophy (Ph.D.) Clinical Psychology degree at the University of Wollongong. Dr Hashworth’s research focused on examining borderline personality disorder, locus of control (i.e., our sense of agency, autonomy, and perceived control over ourselves and our environment), attachment styles (i.e., how we interact in interpersonal situations), and traumatic experiences (particularly childhood trauma).</p>
                    <p>Originally from Canada, Dr Hashworth has extensive experience in a range of public and private mental health settings, such as private practice, community mental health, psychiatric hospitals (inpatient and outpatient), and crisis centres.</p>
                    <p>Dr Hashworth has ample experience implementing evidence-based psychological therapies and has a particular interest in working with adolescents and young adults. Dr Hashworth is an accredited EMDR Consultant, awarded to her by the EMDR Board of Australia (EMDRAA). Dr Hashworth’s primary areas of interest include working with trauma (PTSD and complex trauma), borderline personality disorder, and LGBTQIA+ health. Dr Hashworth uses a range of validated and evidence-based approaches, including, but not limited to:</p>
                    <ul>
                        <li>Eye Movement Desensitisation Reprocessing (EMDR) Therapy</li>
                        <li>Dialectical Behavioural Therapy (DBT)</li>
                        <li>Schema Therapy</li>
                        <li>Cognitive Behavioural Therapy (CBT)</li>
                        <li>Trauma-Focused CBT</li>
                        <li>Acceptance & Commitment Therapy (ACT)</li>
                        <li>Motivational Interviewing (MI)</li>
                        <li>Feedback Informed Treatment (FIT)</li>
                        <li>Exposure Therapy and Response Prevention (ERP)</li>
                    </ul>
                    <p>When working with Dr Hashworth, she focuses on supporting you to build your resilience, achieve your goals, develop a sense of control and security within yourself, increase your self-worth, self-understanding, and self-compassion, acquire skills and techniques to better manage difficult situations, and improve your overall wellbeing. Dr Hashworth works with you to ensure you feel safe and comfortable and tailors treatment to your individual needs. She welcomes individuals of all gender and sexual orientations, identities, and expressions, with a strong focus on empowering LGBTQIA+ individuals.</p>
                    <p>Dr Hashworth works with Medicare, NDIS (self and plan managed), WorkCover, and private clients.</p>
                `
            },
            {
                img: elizabethProfile,
                heading: "Elizabeth Procter",
                title: "Counsellor",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Elizabeth is in her final year of a Bachelor of Counselling and is a member of the Australian Counselling Association (ACA).</p>
                    <p>Elizabeth’s goal is to help you regain control because she believes that you are the expert in your life.  She has a gentle and empathetic approach, and is a patient and non-judgmental listener.</p>
                    <p>She has also completed additional studies in supporting trauma survivors.</p>
                    <p>Her specialty areas include trauma recovery, moving through grief and loss, supporting personality vulnerabilities, working with cult survivors and rebuilding one’s self-identity after institutionalised abuse.</p>
                    <p>Elizabeth works within a trauma-informed approach, person-centered therapy, DBT, and positive psychology.  She embraces the LGBTQIA+ and neurodivergent communities as well as other minority groups.</p>
                    <p>Elizabeth can see clients privately or NDIS (self and plan managed).</p>
              `
            },
            {
                img: jasminProfile,
                heading: "Jasmin Cox",
                title: "Psychologist",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Jasmin is a registered psychologist with AHPRA who is committed to walking alongside her clients in their journey towards positive mental health and wellbeing. With a breadth of experience, she has worked with adolescents, adults and families across various areas including anxiety, depression, addiction, parenting, relationship challenges, trauma, personality disorders as well as assessment and diagnosis. Jasmin also enjoys facilitating therapeutic groups and providing supervision to emerging therapists. She is committed to ensuring every individual feels heard, valued and supported.</p>
                    <p>Jasmin takes an eclectic approach to therapy integrating cognitive behavioural therapy (CBT), acceptance and commitment therapy (ACT), Schema Therapy, and Dialectical Behaviour Therapy (DBT) skills. Currently, she is pursuing training in EMDR, further enriching her therapeutic toolkit.</p>
                    <p>Jasmin operates from a person-centered, trauma-informed perspective, valuing her clients' personal strengths and acknowledging they are the experts of their own experiences of mental illness. She is passionate about creating a safe and inclusive space for individuals from all walks of life, including members of the LGBTQIA+ community, and is committed to supporting all people in their journey towards mental wellbeing and growth.</p>
                    <p>Jasmin can see clients under Medicare, NDIS (self and plan managed), WorkCover, and private clients.</p>
                `
            },
            {
                img: juliaProfile,
                heading: "Julia Messore",
                title: "Provisional Psychologist",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Julia is a Provisional Psychologist registered with AHPRA. She has recently completed her Bachelor of Social Sciences (Sociology) and Bachelor of Psychological Science (Honours) graduating with distinction, and is currently completing her Master of Professional Psychology (School Psychology) at the University of Wollongong. Julia has experience working with young adults to effectively deliver mental health programs, and she has co-facilitated group therapy catered for individuals with borderline personality disorder (BPD) and trauma.</p>
                    <p>She also regularly volunteers with older Australians to reduce their loneliness and isolation through providing companionship.</p>
                    <p>Julia is interested in working with women’s mental health, the LGBTQIA+ community, depression, and anxiety. She can also conduct ADHD and autism assessments.</p>
                    <p>Julia can see clients privately or NDIS (self and plan managed).</p>
                `
            },
            {
                img: lukeProfile,
                heading: "Luke Fullagar",
                title: "Psychologist",
                pronouns: "He/Him",
                researchButton: false,
                description: `
                    <p>Luke is a registered psychologist with AHPRA who is devoted to helping clients walk a path
                    towards a sense of comfort, safety, meaning and wellbeing. Luke has worked with adults from
                    diverse backgrounds, cultures and identities and draws satisfaction from finding ways for people
                    to affirm who they are and cherish themselves for who they choose to be. He has worked with a
                    range of concerns including depression, anxiety, adjustment issues, PTSD, acute stress,
                    trauma, workplace issues as well as assessment and diagnosis. He has significant experience
                    in the worker’s compensation scheme and has an intimate knowledge of the process of, and
                    stakeholders to, a claim.</p>
                    <p>Luke takes an integrative approach to therapy drawing from a range of treatments including
                    Cognitive Behavioural Therapy (CBT), Acceptance and Commitment Therapy (ACT),
                    Mindfulness-Based Cognitive Therapy (MBCS), Mentalisation Therapy, Attachment Focussed
                    Therapy, Schema Therapy, Narrative Therapy and Dialectical Behaviour Therapy (DBT). Luke
                    is training in EMDR Therapy to assist people with PTSD and complex trauma.</p>
                    <p>Luke works with a client-focussed and trauma-informed focus that seeks to affirm the inherent
                    strengths and values of his clients and considers clients as the authority on their own life. He is
                    committed to psychological safety and works to maintain an inclusive environment for all
                    identities and cultures, including First Nations people, people who identify as having a disability
                    and the LGBTQIA+ community. He has experience with all of these populations and seeks to
                    affirm and validate the unique experiences that every client brings to their therapy.</p>
                    <p>Luke can see clients under Medicare, WorkCover, NDIS (plan and self managed), and private clients.
                    </p>
                `
            },
            {
                img: nicoleProfile,
                heading: "Nicole Herrald",
                title: "Counsellor",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Nicole completed her Bachelor of Psychological Sciences from Griffith University before undertaking a Diploma of Counselling. She is now studying a Master of Counselling through Torrens University and will gain membership with the Australian Counselling Association (ACA) upon completion. Nicole’s background in mental health has been an integral part of her work in the social work and counselling fields. She believes that giving young people the tools to cope with their emotions and stressors sets them on the best course for an emotionally fulfilling life.</p>
                    <p>Nicole favours a strengths-based approach to help clients realise their own capacity. She has extensive training in Cognitive Behavioural Therapy (CBT) and Dialectical Behaviour Therapy (DBT) and has a special interest in adolescent development.</p>
                    <p>Nicole enjoys working with the LGBTQIA+ community, trauma, personality disorders, mood and anxiety disorders, grief, and many more presentations.</p>
                    <p>Nicole can see NDIS clients (self and plan managed) and private clients.</p>
                `
            },
            {
                img: oliviaProfile,
                heading: "Olivia Green",
                title: "Clinical Psychologist",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Olivia is a Clinical Psychologist with AHPRA. She completed her Master of Clinical Psychology at The University of Sydney, as well as a Master of Research and Bachelor of Psychology (Honours) at Macquarie University. Olivia has extensive experience working in both public and NGO mental health settings, including community mental health, inpatient mental health units, headspace, and The Central Australian Aboriginal Congress in Alice Springs. Olivia is an authentic, open-minded, and compassionate therapist who draws upon numerous therapeutic approaches to provide individualised evidence-based treatment to clients. She recognises societal and systemic issues that contribute to mental ill health, working with individuals to feel safe and empowered to facilitate healing and growth. Areas of interest include personality disorders, trauma, mood and anxiety disorders (including depression, bipolar, OCD, and phobias), ecopsychology, grief and loss, and working with older adults. Olivia works with clients aged 16+ and welcomes working with members of the LGBTQIA+ community. She utilises a range of evidence-based therapeutic approaches, including:</p>
                    <ul>
                        <li>Cognitive Behavioural Therapy (CBT)</li>
                        <li>Dialectical Behavioural Therapy (DBT)</li>
                        <li>Exposure and Response Prevention</li>
                        <li>Acceptance and Commitment Therapy (ACT)</li>
                        <li>Eye Movement Desensitisation Reprocessing (EMDR) Therapy</li>
                    </ul>
                    <p>In her spare time, Olivia loves playing soccer, spending time with friends and family, and travelling.</p>
                    <p>Olivia can see clients privately, under Medicare, or NDIS (self and plan managed).</p>
                `
            },
            {
                img: rachelProfile,
                heading: "Rachel Mintoff",
                title: "Accredited Social Worker",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Rachel is an accredited Social Worker with the Australian Association of Social Workers (AASW) and a proud late-diagnosed AUDHDer (Autistic + ADHD). Rachel holds a Bachelor of Social Work with Distinction from the University of Wollongong and is currently completing further studies to develop and teach health practitioners and clients about neuroaffirming and disability-inclusive practices.</p>

                    <p>Rachel's practice is deeply rooted in her own lived experience and career as a Social Worker. She is passionate about providing neuroaffirming, trauma-informed, and inclusive support that empowers her clients. Additionally, she is passionate about supporting the LGBTQIA+ community and prioritises inclusivity. Rachel draws on a variety of therapeutic approaches, including Acceptance and Commitment Therapy (ACT), Narrative Therapy, Person-Centered Therapy, Coaching, and Strengths-Based methods, all tailored to meet her clients' individual needs and goals. She will also be undergoing training in Eye Movement Desensitization and Reprocessing (EMDR) Therapy.</p>
                    
                    <p>With a strong belief that clients are the experts in their own lives, Rachel works alongside them, offering both therapeutic and practical support. Her approach is client-focused, goal-oriented, and always sensitive to the unique challenges faced by each individual.</p>
                    
                    <p>Driven by her passion to support neurodivergent individuals, Rachel spends her spare time researching and trialing strategies and methods to enhance her practice. She brings this dedication into her work, helping clients navigate their journeys in a way that feels true to them.</p> 
                    
                    <p>Rachel's personal and professional experiences are at the heart of her practice, making her a compassionate and effective ally in her clients' journeys toward healing and empowerment.</p>
                    
                    <p>Rachel can see clients privately or NDIS (self and plan managed).</p>
                `
            },
            {
                img: salaProfile,
                heading: "Sala Ghanem",
                title: "Counsellor",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Sala currently holds a Bachelor of Psychology (Honours) and aims to pursue a Masters of Clinical Psychology. Her educational background and previous roles in youth mentoring and co-facilitating roles reflect a strong foundation in the field. She is particularly drawn to employing a client-centred holistic approach when working with individuals.</p>
                    <p>In her approach to counselling, Sala places a special emphasis on three key therapeutic modalities: Cognitive Behavioural Therapy (CBT), Mindfulness-Based Therapy (MBT), and Dialectical Behavioural Therapy (DBT). These methods highlight her commitment to connect with and empower individuals. Particularly, Sala’s passion lies in creating an inclusive and supportive space for everyone, ensuring equal opportunities for each person to unlock their fullest potential. Sala is bilingual in English and Arabic and is passionate about supporting individuals of diverse backgrounds. </p>
                    <p>Sala’s expertise extends to group settings, where she has been involved in co-facilitating various DBT group therapy programs. Sala is interested in working with individuals with borderline personality disorder (BPD), trauma, the LGBTQIA+ community, depression, anxiety, bipolar disorder, and many more presentations. </p>
                    <p>Sala can see NDIS clients (self and plan managed) and private clients.</p>
                `
            },
            {
                img: taniaProfile,
                heading: "Tania Attley",
                title: "Psychologist",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Tania is a Registered Psychologist with AHPRA and completed her Bachelor of Psychology at Charles Sturt University in 2013. Tania has 10 + years’ experience working with children, adolescents and adults. Areas of interest include trauma (developmental, complex & PTSD), and how this impacts neurobiology, behaviour and personality. She has completed a Graduate Certificate in Developmental Trauma and runs training for agencies on working therapeutically with families who have experienced trauma. Tania is also a credentialed eating disorder clinician with ANZAED. More recently, she has become more interested in understanding the links between nutrition, exercise, hormones and mental health issues. Tania views mental health as holistic in nature, thus not looking at mental health symptoms in isolation but rather conceptualising the person’s health and wellbeing as a whole (i.e. inclusive of physical and spiritual health).</p>
                    <p>Tania sees clients with depression, anxiety, trauma, borderline personality disorder, and dissociative disorders, as well as neurodivergent clients, operating within a client centred, recovery oriented framework that builds capacity, fosters empowerment and instils hope. Tania has a genuine curiosity in hearing people’s stories and how they view the world. She focuses on the importance of building a safe therapeutic environment where clients can work towards personal growth, agency and self-efficacy. A range of evidence-based interventions are adopted in therapy including EMDR Therapy, Schema Therapy, Cognitive Behavioural Therapy, Acceptance and Commitment Therapy, Dialectical Behaviour Therapy skills, psychoeducation, relaxation and mindfulness training. Additionally, Tania is currently in the process of seeking board approval as a Supervisor.</p>
                    <p>Tania adopts creative interventions using art, play and storytelling to ensure treatment is tailored to the individual. Tania also has experience in conducting Autism assessments using a number of evidence based approaches, adaptive behaviour (Vineland and ABAS), behavioural assessments (including ADHD and conduct disorders), cognitive assessments (WISC-IV & WAIS), mental health/personality assessments (PAI) and trauma assessments (TSCC, TSCYC & TSI-2).</p>
                    <p>Tania has two little kiddies of her own who she spends most of her time with. Her other interests include anything beach or ocean related, self-development, reading and lifting heavy things, with the occasional run.</p>
                    <p>Tania can see clients privately, under Medicare, NDIS (self and plan managed), Open Arms, and Workcover.</p>
                `
            }
        ]
    },
    behindTheScenesStaff: {
        header: "Behind the Scenes",
        staff: [
            {
                img: alanaProfile,
                heading: "Alana Joseph",
                title: "Practice Manager",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Alana is in charge of all of the behind–the-scenes action here at Hashworth Psychology Pty Ltd. She is trained as a primary school teacher and has always been interested in mental health, as she has extensive experience working with additional needs and in embedding trauma informed practice into her work. She is very personable and loves working with people, so be sure to say hi and ask her any questions when you see her at the office. When she is not at work, you’ll find Alana spending every spare minute with her partner and dogs, or lifting heavy things in the gym!</p>
                `
            },
            {
                img: caitlinProfile,
                heading: "CJ Willer",
                title: "Administration",
                pronouns: "They/Them",
                researchButton: false,
                description: `
                    <p>CJ brings a strong passion for and interest in mental health and LGBTQIA+ inclusion to their role at Hashworth Psychology Pty Ltd. This is supported by their experience in customer service and skills in organisation. Outside of work, CJ enjoys reading, dancing, and puzzles.</p>
                `
            },
            {
                img: KatProfile,
                heading: "Katrina Beretov",
                title: "Administration",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Kat is currently seeking a Bachelor of Laws & Bachelor of Arts (Psychology) at the University of Wollongong, where she has had the opportunity to marry two interests of hers; understanding why humans do what they do, and the legal system that regulates social movement. She intends to utilise her studies to help marginalised groups in navigating complex welfare systems through advocacy and policy change. She has a keen interest in pursuing a career as a mental health practitioner, helping those who identify as Culturally and Linguistically Diverse (CALD), senior citizens, LGBTQIA+, facing socio-economic adversity and individuals identifying with a disability.
                    With almost a decade in customer service roles, as well as thorough administrative/front desk experience, Kat is patient and always eager to greet you with a smile and a giggle. Kat has native fluency in the English language and proficient in spoken Macedonian.</p>
                    <p>When Kat is not in the clinic, or attending classes, you can find her in her Baba's kitchen making traditional Macedonian sweets, at pilates or in the gym, grabbing a cheeky sweet treat with her friends, or singing her heart out with her Dedo to TurboFolk.</p>
                `
            },
            {
                img: madelineProfile,
                heading: "Madeline Shalala",
                title: "Therapy Assistant",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Madeline is a fourth-year university student studying a Bachelor of Psychological Science, minoring in Criminology. She intends to complete her honours year in psychology, and then go on to complete a Masters degree.</p>
                    <p>Madeline aspires to work within clinical psychological settings, and hopes to one day be given the opportunity to work with courts and prisons to deliver clinical psychological care and assessment to those within the justice system. To achieve these aspirations, she works with Hashworth Psychology, and is also training as a Crisis Supporter with Lifeline.</p>
                    <p>When not busy with studying and volunteering, Madeline enjoys thrift shopping, indulging her sweet tooth, and spending time with her family.</p>
                `
            },
            {
                img: marcusProfile,
                heading: "Marcus Celebi",
                title: "Therapy Assistant",
                pronouns: "He/Him/They/Them",
                researchButton: false,
                description: `
                    <p>Marcus has worked on their Bachelor of Psychological Science (Pathway to Primary Education) course for 3 years, going onto a 4th. With 5 years of customer service and hospitality experience, Marcus believes in taking care of others and making sure they are safe and welcome in a psychological environment and because of this, is working at Hashworth Psychology Pty Ltd. They are currently working towards their honours and hopes to work in child and family psychology in the future. When they're not studying, Marcus loves listening to music, going out for walks on the beach and running their local uni's board game club/society.</p>
                `
            },
        ]
    }
}