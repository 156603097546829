import React from "react";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

import Navbar from "../navbar/Navbar";
import ContactUs from "../contactUs/ContactUs";
import Custom from "./hiring-config";
import "./Hiring.css";
import Footer from "../footer/Footer";

class Hiring extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const roleSectionDescription = { __html: DOMPurify.sanitize(Custom.roleSection.description) };
        const criteriaSectionDescription = { __html: DOMPurify.sanitize(Custom.criteriaSection.description) };
        const reachOutSectionDescription = { __html: DOMPurify.sanitize(Custom.reachOutSection.description) };

        return (
            <>
                <Helmet>
                    <title>We're Hiring!</title>
                    <meta name="description" content="Due to high demand of our services, Hashworth Psychology Pty Ltd is looking to expand our talented team with a general psychologist, clinical registrar, or clinical psychologist." />
                </Helmet>

                <Navbar {...this.props} page="about" />
                <div className="titleBgImg">
                    <div className="titleBanner">
                        <div className="titleContent">
                            <h1>{Custom.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div>
                        <h2 className="contentHeader">{Custom.subtitle}</h2>
                        <p className="shortDesc">{Custom.description}</p>
                    </div>
                    <div className="box">
                        <h4>{Custom.roleSection.header}</h4>
                        <p dangerouslySetInnerHTML={roleSectionDescription}></p>
                    </div>
                    <div>
                        <h2 className="contentHeader">{Custom.benefitsSection.header}</h2>
                        <div className="benefitsGrid">
                            {Custom.benefitsSection.benefits.map((benefit, i) =>
                                <div key={i} className="benefitsTile">
                                    <p className="benefitsText">{benefit}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="box">
                        <h4>{Custom.criteriaSection.header}</h4>
                        <p dangerouslySetInnerHTML={criteriaSectionDescription}></p>
                    </div>
                    <div>
                        <h2 className="contentHeader">{Custom.reachOutSection.header}</h2>
                        <p dangerouslySetInnerHTML={reachOutSectionDescription} className="shortDesc"></p>
                    </div>
                </div>
                <ContactUs />
                <Footer />
            </>
        );
    }
}

export default Hiring;