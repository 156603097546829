import React from "react";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

import InfoRow from "../infoRow/infoRow";
import Navbar from "../navbar/Navbar";
import ContactUs from "../contactUs/ContactUs";
import Custom from "./about-config";
import "./About.css";
import Footer from "../footer/Footer";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const socialMediaText = { __html: DOMPurify.sanitize(Custom.socialMediaText) };

        return (
            <>
                <Helmet>
                    <title>About Hashworth Psychology Pty Ltd</title>
                    <meta name="description" content="Learn about our dedicated clinical team of psychologists and behind-the-scenes staff that look forward to supporting you on your mental health journey." />
                </Helmet>

                <Navbar {...this.props} page="about" />
                <div className="titleBgImg">
                    <div className="titleBanner">
                        <div className="titleContent">
                            <h1>{Custom.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <p className="shortDesc">{Custom.description}</p>
                    <div dangerouslySetInnerHTML={socialMediaText} className="socialMediaText"></div>
                    <div className="facebookPlugin">
                        <iframe
                            title="mapsIframe"
                            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fhashworthpsychology&tabs&width=340&height=70&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
                            width="340"
                            height="70"
                            scrolling="no"
                            frameBorder="0"
                            allowFullScreen={true}
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        />
                    </div>
                    <h3 className="staffHeader">{Custom.staffHeader}</h3>
                    <div>
                        <h4>{Custom.clinicalStaff.header}</h4>
                        {Custom.clinicalStaff.staff.map((staff, i) =>
                            <div className="staff" key={i} >
                                <InfoRow subject={staff} />
                            </div>
                        )}
                    </div>
                    <div>
                        <h4>{Custom.behindTheScenesStaff.header}</h4>
                        {Custom.behindTheScenesStaff.staff.map((staff, i) =>
                            <div className="staff" key={i} >
                                <InfoRow subject={staff} />
                            </div>
                        )}
                    </div>
                </div>
                <ContactUs />
                <Footer />
            </>
        );
    }
}

export default About;