export default {
    title: "Submit an Online Enquiry",
    subtitle: "Got a question? Let us know and we'll get back to you as soon as we can.",
    submitSuccessMessage: `
        <p>Thank you for your enquiry. We will get back to you as soon as possible.</p>
        <p>Please feel free to contact us on <a href="tel:0491683910">0491 683 910</a> or via email at <a href="mailto:connect@hashworthpsychology.com.au">connect@hashworthpsychology.com.au</a> for any further queries.</p>
    `,
    submitFailMessage: `
        <p>There seems to be a problem submitting your enquiry. Please try again later.</p>
        <p>Otherwise, feel free to contact us on <a href="tel:0491683910">0491 683 910</a> or via email at <a href="mailto:connect@hashworthpsychology.com.au">connect@hashworthpsychology.com.au</a>.</p>
    `,
    faqButton: {
        title: "Check out our FAQ page",
        description: "Didn't find what you were looking for?"
    }
}