import React from "react";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";

import "./NavbarButton.css";

function NavbarButton(props) {
    return (
        <Button size="lg" variant="link-dark" className={"navbarButton " + props.selectedButton}>
            {props.children}
        </Button>
    );
}

export default withRouter(NavbarButton);