import React from "react";
import DOMPurify from "dompurify";
import { withRouter } from "react-router-dom";

import Button from "react-bootstrap/Button";
import "./infoRow.css";

class InfoRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const subject = this.props.subject;
        //Sanitise to stop XSS attacks, must be in form of object with __html as key
        const description = { __html: DOMPurify.sanitize(subject.description) };

        const navigateToStudies = () => {
            this.props.history.push("/studies");
            window.scrollTo(0, 0);
        };

        let researchButton;

        if (subject.researchButton) {
            researchButton = (
                <Button className="clickableBtn bookingBtn" size="lg" variant="primary" onClick={navigateToStudies}>
                    Research & Publications
                </Button>
            );
        }

        return (
            <>
                <div className="contactRow">
                    <div className="staffRow">
                        {subject.img && 
                            <img src={subject.img} className="staffProfileImg" alt={subject.heading}></img>}
                        <div className="staffContent">
                            <h4>{subject.heading}</h4>
                            <h6>{subject.pronouns}</h6>
                            <h5 className="staffTitle">{subject.title}</h5>
                            <div dangerouslySetInnerHTML={description}></div>
                            {researchButton}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(InfoRow);