import clinicImg5 from "../../img/hashworth-clinic-5.jpg"
import clinicImg2 from "../../img/hashworth-clinic-2.jpg"
import clinicImg4 from "../../img/hashworth-clinic-4.jpg"

export default {
    title: "Hashworth Psychology",
    description: "Sometimes it is hard to find hope when you’re surrounded by darkness. We will hold the hope for you until you’re ready to hold it for yourself.",
    content: {
        heading: "Let's get through this together",
        description: `
            <p>We are a LGBTQIA+ affirming practice located in Wollongong CBD. We support children, adolescents, and adults to achieve recovery from mental health difficulties and create a life worth living. Our values include fostering autonomy, resilience, respect, hope, and empowerment, while ensuring safety, understanding, and flexibility.</p>
            <p>We are:</p>
            <ul>
                <li>Wollongong based therapists</li>
                <li>LGBTQIA+ affirming</li>
                <li>Borderline personality disorder informed</li>
                <li>Trauma (PTSD and complex PTSD) informed</li>
                <li>OCD informed</li>
                <li>Neurodivergence affirming</li>
                <li>Effectively trained in treating anxiety, depression, and stress</li>
                <li>Able to tailor therapy to your individual needs</li>
                <li>Wheelchair accessible</li>
            </ul>
            <p>We hold extensive experience with:</p>
            <ul>
                <li>EMDR Therapy</li>
                <li>Individual and Group DBT Therapy</li>
                <li>Schema Therapy</li>
                <li>Cognitive Behavioural Therapy</li>
                <li>Trauma Focused Cognitive Behavioural Therapy</li>
                <li>Acceptance and Commitment Therapy</li>
                <li>Couples Therapy</li>
                <li>ADHD, Autism, Cognitive, and Functional Assessments</li>
                <li>Gender affirming WPATH Assessments</li>
                <li>Clinical Diagnostic Assessments (inc. BPD and PTSD)</li>
            </ul>
            <p>We are accepting new clients and are available during standard business hours, as well as evenings, and weekends.</p>
        `,
        imgs: [
            {
                img: clinicImg5,
                alt: "A picture of the waiting room for the clinic"
            },
            {
                img: clinicImg2,
                alt: "A picture of the clinic"
            },
            {
                img: clinicImg4,
                alt: "Another picture of the clinic"
            }
        ],
        button: {
            title: "Check out our services >",
            link: "/services"
        }
    }
}