import React from "react";
import DOMPurify from "dompurify";
import { withRouter } from "react-router-dom";

import "./DropdownBox.css";
import { Accordion, Card } from "react-bootstrap";
import { scrollTo } from "../../helpers/scrollHelper";

class DropdownBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.scrollCard = React.createRef();
    }

    handleClick = () => {
        scrollTo(this.scrollCard);
    }

    render() {
        const answer = { __html: DOMPurify.sanitize(this.props.subject.answer) };

        return (
            <Card ref={this.scrollCard}>
                <Accordion.Toggle as={Card.Header} eventKey={this.props.index} onClick={this.handleClick}>
                    {this.props.subject.question}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={this.props.index}>
                    <Card.Body dangerouslySetInnerHTML={answer}>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        );
    }
}

export default withRouter(DropdownBox);