import React from "react";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";

import ContactRow from "../contactRow/ContactRow";

import "./ContactUs.css";
import Custom from "./contactUs-config";

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const navigateTo = () => this.props.history.push(Custom.button.link);

        return (
            <div className="contactComponent">
                <div className="title">
                    <h4>{Custom.title}</h4>
                    <p>{Custom.description}</p>
                </div>
                <div className="contactUs">
                    <div className="leftContact">
                        <div className="contactDetails">
                            {Custom.leftDetails.map((detail, i) => {
                                return(<ContactRow key={i} subject={detail}/>);
                            })}
                        </div>
                        <Button className="clickableBtn" size="lg" variant="primary" onClick={navigateTo}>
                            {Custom.button.title}
                        </Button>
                    </div>
                    <div className="rightContact">
                        <div className="rightDetails">
                            {Custom.rightDetails.map((detail, i) => {
                                return(<ContactRow key={i} subject={detail}/>);
                            })}
                        </div>
                        <iframe 
                            className="contactMap"
                            src="https://www.google.com/maps/embed/v1/place?q=1A+Young+Street,+Wollongong+NSW+2500,+Australia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" 
                            frameBorder="0" 
                            allowFullScreen="" 
                            aria-hidden="false" 
                            tabIndex="0"
                            title="clinicMap"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ContactUs);