import React from "react";

import "./Footer.css";
import Custom from "./footer-config";


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="footer">
                <p className="footerText">{Custom.footer}</p>
                <br />
                <p className="footerText">{Custom.acknowledgement}</p>
            </div>
        );
    }
}

export default Footer;