import React from "react";

import Button from "react-bootstrap/Button";
import Footer from "../footer/Footer";

import Navbar from "../navbar/Navbar";
import "./MissingPage.css";

class MissingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const navigateTo = () => this.props.history.push("/");

        return (
            <>
                <Navbar {...this.props} />
                <div className="missingPageBox">
                    <div className="missingPageContent">
                        <h1>Oops!</h1>
                        <p>It looks like you've navigated to a page that doesn't exist.</p>
                        <Button className="clickableBtn" size="lg" variant="primary" onClick={navigateTo}>
                            Click here to return to the homepage
                        </Button>
                    </div>    
                </div>
                <Footer/>
            </>
        );
    }
}

export default MissingPage;