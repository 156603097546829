export default {
    title: "Adolescents",
    description: `
        <p>Adolescence can be a particularly difficult time for many of us. During adolescence, we experience some of the most significant changes in our lives, which can be very exciting, but also very overwhelming. </p>
        <h4>The Science Behind the Adolescent Brain:</h4>
        <p>From a scientific perspective, there are immense changes to the brain during adolescence. During this period, a part of our brain called the amygdala is strengthened. Our amygdala is primarily associated with emotions (especially fear and stress), impulses, aggression, and motivation. Because the amygdala cannot differentiate between real danger and perceived danger, it responds the same when we are in a life-threatening situation, for example, if a car is coming towards you at 100km/hour, or if your partner isn’t responding to your texts. The part of the brain that is responsible for deciding whether we are in real danger or not is what’s called the prefrontal cortex. </p>
        <p>The prefrontal cortex begins developing during adolescence but continues into our mid to late 20s. Our prefrontal cortex is responsible for all of the higher-level thinking that we all do, including focusing our attention, predicting consequences and anticipating outcomes, controlling impulses, and managing our emotional responses. </p>
        <p>This means that during adolescence, when we are faced with difficult situations, our amygdala gets triggered and often responds with extreme emotions, but our prefrontal cortex hasn’t yet learnt how to manage these emotions. Adolescents then have difficulties controlling their impulses and emotions and tend to respond hastily, since their prefrontal cortex isn’t able to step in and better manage the situation. </p>
        <h4>How Can a Psychologist Help Me?</h4>
        <p>Working with a psychologist may be especially useful if you are experiencing: </p>
        <ul>
            <li>Difficulties within your peer group</li>
            <li>Feeling excluded from others</li>
            <li>Loneliness</li>
            <li>Increased stress</li>
            <li>Difficulties managing everything on your plate (school, home, work, etc) </li>
            <li>Sadness</li>
            <li>Feeling worthless or as if no one understands or cares about you</li>
            <li>Self-harm or self-destructive behaviours</li>
            <li>Suicidal thoughts or behaviours</li>
            <li>Difficulties with your body image</li>
            <li>Anxiety in social situations</li>
            <li>Uncertainties about who you are or who you want to be</li>
            <li>Questions about your sexual or gender identity and expression</li>
            <li>Difficulties controlling anger outbursts</li>
            <li>Finding yourself crying or upset all of the time</li>
            <li>Physically hurting others</li>
            <li>Being physically, emotionally, verbally, or sexually hurt by others</li>
            <li>Bullying (in person or cyberbullying) </li>
            <li>Feeling peer pressured to do things that you don’t want to do</li>
            <li>Partaking in drugs or substances</li>
            <li>Spending hours each day hiding in your room and avoiding contact with others</li>
        </ul>
        <p>These are just some of the many things that a psychologist can help you with. If you are struggling, please reach out to us or speak to a person you trust and ask them to get in contact with us.</p>
    `,
    img: "",
    parentsInfo: {
        heading: "Information for Parents",
        description: `
        Attention parents and caregivers! If you are reading this, you may be feeling confused, overwhelmed, angry or disappointed at or towards your child. You may have noticed some concerning signs come up recently or perhaps signs that have been there for a long time. You may feel as if you do not know how to support your child. You may feel as if they are pulling away from you and no longer enjoy spending time with you, or maybe you find yourself resenting them. You may feel like it’s harder to communicate with them and that you’re running out of options (or patience). Or perhaps these situations haven’t yet happened but you’re here to prevent difficulties from unfolding for your child.\n
        If any of the above statements even partially resemble your experience, you are likely in the same boat as many other parents of adolescents. \n
        Adolescence is a time of significant growth and change, both positive and negative. During adolescence, major changes occur through their brain (see the Adolescents section above). These changes can make it extremely difficult for parents to understand, relate to, help, or support their child. This is a completely normal experience. You are not alone.\n
        Your child may find it difficult to be able to talk to you and open up to you. Or perhaps they are talking to you, but you just don’t know how to help. You are doing your very best but there is only so much you can do.\n
        It may be the right time to discuss the options of seeing a psychologist and see if your child may find it helpful to be able to speak to someone and work through their challenges. \n
        At Hashworth Psychology Pty Ltd, we have strong values around independence and autonomy. If you and your child are willing to work with us and engage in sessions, we are more than happy to work with you. If however, your child refuses to attend or engage, or doesn’t have any goals or areas that they want help with, we do not feel it is right to force them to be here. Seeing a psychologist and opening up isn’t always easy and so we are here to support you and your family when your child feels they are ready to engage. We take extra caution to ensure that your child does not leave with a negative experience of therapy, and therefore need to make sure they are fully on board and willing to engage in regular sessions.\n
        It is important that we remember that positive change doesn’t happen overnight nor can an adolescent make those changes completely on their own. Adolescents benefit from being supported by a mental health practitioner as well as their family and we strongly promote allowing them the space to make changes over time. \n
        As soon as you notice the signs that something isn’t right at home or at school, seek help early. Speak to your child and see if they are willing to receive help and support through their difficult time(s). \n
        `
    }
}