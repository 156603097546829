import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../navbar/Navbar";
import ContentBox from "../contentBox/ContentBox";
import ContactUs from "../contactUs/ContactUs";

import "./Home.css";
import Custom from "./home-config";
import Footer from "../footer/Footer";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Hashworth Psychology Pty Ltd</title>
                    <meta name="description" content="Hashworth Psychology Pty Ltd is an LGBTQIA+ inclusive service in Wollongong NSW offering support for individuals seeking a safe space for therapy and psychological recovery. Our skilled team of psychologists can provide support for borderline personality disorder, trauma (complex trauma and PTSD), depression, anxiety, OCD, stress, bipolar disorder, and other mental health challenges." />
                </Helmet>

                <Navbar {...this.props} page="home" />
                <div className="headerBgImg">
                    <div className="headerBanner">
                        <div className="headerContent">
                            <h1 className="headerText">{Custom.title}</h1>
                            <h5 className="headerText">{Custom.description}</h5>
                        </div>
                    </div>
                </div>
                <ContentBox subject={Custom.content} />
                <ContactUs />
                <Footer />
            </>
        );
    }
}

export default Home;