import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../navbar/Navbar";
import ContactUs from "../contactUs/ContactUs";
import DropdownBox from "../dropdownBox/DropdownBox"
import Custom from "./faqs-config";
import "./FAQs.css";
import Footer from "../footer/Footer";
import { Accordion } from "react-bootstrap";

class FAQs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Frequently Asked Questions</title>
                    <meta name="description" content="Have any questions for Hashworth Psychology Pty Ltd? Check here for answers to questions about our therapy sessions, fees, cancellation policy and other common topics." />
                </Helmet>

                <Navbar {...this.props} page="faqs"/>
                <div className="titleBgImg">
                    <div className="titleBanner">
                        <div className="titleContent">
                            <h1>{Custom.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="faqContent">
                    <div className="faqSubcontent">
                        <h4>{Custom.subtitle}</h4>
                        <p>{Custom.description}</p>
                    </div>
                    <Accordion>
                        {Custom.questions.map((question, i) => {
                            const index = i.toString();
                            return(<DropdownBox key={i} index={index} subject={question}/>);
                        })}
                    </Accordion>
                </div>
                <ContactUs/>
                <Footer/>
            </>
        );
    }
}

export default FAQs;