export default {
    title: "Join Our Team",
    subtitle: "We're hiring!",
    description: "As a private practice in Wollongong CBD, we are looking to expand our team to enthusiastic, supportive, and passionate psychologists servicing the broader Illawarra community. Our values include fostering autonomy, resilience, respect, and empowerment, while ensuring safety, understanding, and flexibility. We have a particular interest in working with adolescents and adults by supporting trauma survivors, individuals with personality disorder presentations, and the LGBTQIA+ community.",
    roleSection: {
        header: "Who are we looking for?",
        description: `<p>We are searching for a general psychologist, clinical registrar, or clinical psychologist to join our practice. Provisional psychologists awaiting registration are encouraged to apply. In this role, you would:</p>
        <ul>
            <li>Deliver high-quality support and interventions to adolescents and adults using evidence-based methods</li>
            <li>Provide clinical services to individuals, couples, and families, as needed</li>
            <li>Engage in regular trauma-focused professional development, as well as training in your relevant scope of practice</li>
            <li>Liaise with relevant professionals, including but not limited to psychologists, teachers, GPs, psychiatrists, paediatricians, case managers, and social workers</li>
            <li>Have the opportunity for flexible hours, including evenings and weekends, if preferred</li>
            <li>Develop your interests and skills through frequent group and peer supervision opportunities</li>
            <li>Collaborate, respect, and support a team of like-minded clinicians</li>
            <li>Engage in constructive case formulations and treatment planning, thereby providing individually tailored, personalised, and high-quality care</li>
        </ul>
        `
    },
    benefitsSection: {
        header: "Benefits",    
        benefits: [
            "Excellent remuneration with career progression pathways",
            "Regular and frequent individual supervision focused on supporting you in your present and future development as a clinician",
            "Regular Continuous Professional Development (CPD) opportunities",
            "Monthly social outings and team-building opportunities",
            "Flexible hours",
            "Support and guidance in developing and working with your ideal clients",
            "Autonomy to implement your skills and knowledge in a way that you feel will contribute to successful therapeutic outcomes",
            "Referrals targeted to your interests",
            "Opportunities to diversify your practice and skill-set (e.g., group therapy)"
        ]
    },
    criteriaSection: {
        header: "What you'll need",
        description: `
        <ul>
            <li>Registration with AHPRA (Provisionals awaiting registration are encouraged to apply)</li>
            <li>Eligibility for a Medicare Provider Number</li>
            <li>A recent Working with Children’s Check (or the ability to obtain one prior to commencement) </li>
            <li>Ability to work independently with a willingness and desire to be part of a growing team </li>
            <li>A commitment to participating in internal and external professional development</li>
            <li>Experience in evidence-based methods and an inclination to learn advanced techniques as needed</li>
        </ul>
        `,
    },
    reachOutSection: {
        header: "What now?",
        description: `<p>If this sounds like you, please reach out to <a href="mailto:connect@hashworthpsychology.com.au">connect@hashworthpsychology.com.au</a> or contact us on <a href="tel:0491-683-910">0491 683 910</a> to discuss further.</p>`
    }
}