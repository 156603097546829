import React from "react";

import "./ContactRow.css";

class ContactRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const description = 
            this.props.subject.link ? 
            <a href={this.props.subject.link} target="_blank">{this.props.subject.description}</a> :
            <p className="new-line">{this.props.subject.description}</p>;

        return (
            <>
                <div className="contactRow">
                    <img src={this.props.subject.img}></img>
                    <h5>{this.props.subject.heading}</h5>
                    {description}
                </div>
            </>
        );
    }
}

export default ContactRow;