import React from "react";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

import Navbar from "../navbar/Navbar";
import ContactUs from "../contactUs/ContactUs";
import Custom from "./adolescents-config";
import "./Adolescents.css";
import Footer from "../footer/Footer";

class Adolescents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        //Sanitise to stop XSS attacks, must be in form of object with __html as key
        const description = {__html: DOMPurify.sanitize(Custom.description)};

        return (
            <>
                <Helmet>
                    <title>Adolescents</title>
                    <meta name="description" content="We understand that adolescence can be a difficult time. Learn about how we can assist with early intervention and support for adolescents, as well as helpful information for parents." />
                </Helmet>

                <Navbar {...this.props} page="adolescents"/>
                <div className="titleBgImg">
                    <div className="titleBanner">
                        <div className="titleContent">
                            <h1>{Custom.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div dangerouslySetInnerHTML={description} className="adolescentsContent"></div>
                    <div className="parentsInfo">
                        <h4>{Custom.parentsInfo.heading}</h4>
                        <p>{Custom.parentsInfo.description}</p>
                    </div>
                </div>
                <ContactUs/>
                <Footer/>
            </>
        );
    }
}

export default Adolescents;