import React from "react";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";
import DOMPurify from "dompurify";

import "./ContentBox.css";

class ContentBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const navigateTo = () => this.props.history.push(this.props.subject.button.link);
        const description = { __html: DOMPurify.sanitize(this.props.subject.description) };

        return (
            <div>
                <div className="title">
                    <h4>{this.props.subject.heading}</h4>
                </div>
                <div className="contentBox">
                    <div className="leftSection">
                        <div dangerouslySetInnerHTML={description} className="contentDescription" />
                        <Button className="clickableBtn" size="lg" variant="primary" onClick={navigateTo}>
                            {this.props.subject.button.title}
                        </Button>
                    </div>
                    <div className="rightSection">
                        {this.props.subject.imgs.map((img, i) =>
                            <img
                                key={i}
                                src={img.img}
                                className="clinicImg"
                                alt={img.alt}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ContentBox);