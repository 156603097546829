import React from "react";
import DOMPurify from "dompurify";

import Navbar from "../navbar/Navbar";
import Custom from "./enquiries-config";
import "./Enquiries.css";
import Footer from "../footer/Footer";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { isEmpty } from 'lodash';

class Enquiries extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            name: "",
            email: "",
            phone: "",
            enquiry: "",
            errors: {},
            showModal: false,
            loading: false
        };

        this.state = this.initialState;
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleShowModal = () => {
        this.setState(this.initialState);
    }

    onSubmit = e => {
        const errors = this.validate();
        this.setState({ loading: true });
        e.preventDefault();
        this.setState({ errors: errors }, () => {
            if (isEmpty(this.state.errors)) {
                window.Email.send({
                    SecureToken: "d485c6ec-a8e8-4746-8da4-e2104b98cd59",
                    To: 'connect@hashworthpsychology.com.au',
                    From: "connect@hashworthpsychology.com.au",
                    Subject: `Enquiry: ${this.state.name}`,
                    Body: `<html>
                        <h1>New Enquiry</h1>
                        <p><b>Name:</b> ${this.state.name}</p>
                        <p><b>Email:</b> ${this.state.email}</p>
                        <p><b>Phone:</b> ${this.state.phone}</p>
                        <p><b>Enquiry:</b> ${this.state.enquiry}</p>
                    </html>`
                }).then(response => {
                    if (response === "OK") {
                        this.setState({ showModal: true });
                    }
                    else {
                        const errors = {
                            submit: true
                        };
                        this.setState({ errors: errors });
                    }
                    this.setState({ loading: false });
                });
            }
            else {
                this.setState({ loading: false });
            }
        });
    }

    validate() {
        let errors = {};

        if (!this.state.name) {
            errors.name = "Please enter your name";
        }

        if (!this.state.email) {
            errors.email = "Please enter your email";
        }

        if (!this.state.phone) {
            errors.phone = "Please enter your phone number";
        }

        if (this.state.phone.length < 8 || this.state.length > 10) {
            errors.phone = "Please enter a valid phone number";
        }

        if (!this.state.enquiry) {
            errors.enquiry = "Please enter your enquiry";
        }

        return errors;
    }

    render() {
        //Sanitise to stop XSS attacks, must be in form of object with __html as key
        const submitFailMessage = { __html: DOMPurify.sanitize(Custom.submitFailMessage) };
        const submitSuccessMessage = { __html: DOMPurify.sanitize(Custom.submitSuccessMessage) };

        const navigateToFaq = () => {
            this.props.history.push("/faqs");
            window.scrollTo(0, 0);
        }

        return (
            <>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showModal}
                    onHide={this.handleShowModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Enquiry Submitted!</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div dangerouslySetInnerHTML={submitSuccessMessage} />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleShowModal}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Navbar {...this.props} page="enquries" />
                <div className="titleBgImg">
                    <div className="titleBanner">
                        <div className="titleContent">
                            <h1>{Custom.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="enquiriesContent">
                    <div className="enquiriesIntro">
                        <h3>{Custom.subtitle}</h3>
                    </div>
                    {this.state.errors.submit &&
                        <Alert show={this.state.errors.submit} variant="danger">
                            <div dangerouslySetInnerHTML={submitFailMessage} />
                        </Alert>
                    }
                    <div className="equalEnquiriesBox">
                        <div className="equalSection">
                            <Form onSubmit={this.onSubmit}>
                                <Form.Group controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        onChange={this.handleChange}
                                        value={this.state.name}
                                        isInvalid={this.state.errors.name}
                                        maxLength="320"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {this.state.errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        onChange={this.handleChange}
                                        value={this.state.email}
                                        isInvalid={this.state.errors.email}
                                        maxLength="320"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {this.state.errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="phone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter phone number"
                                        onChange={this.handleChange}
                                        value={this.state.phone}
                                        isInvalid={this.state.errors.phone}
                                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {this.state.errors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="enquiry">
                                    <Form.Label>Enquiry</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Enter enquiry here"
                                        onChange={this.handleChange}
                                        value={this.state.enquiry}
                                        isInvalid={this.state.errors.enquiry}
                                        maxLength="500"
                                        rows={4}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {this.state.errors.enquiry}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="enquirySubmitBtn">
                                    <Button variant="primary" type="submit" size="lg" className="clickableBtn enquiryBtn" disabled={this.state.loading || !(this.state.name && this.state.email && this.state.phone && this.state.enquiry)}>
                                        {this.state.loading ?
                                            <>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                <span className="sr-only">Loading...</span>
                                            </>
                                            : "Submit"}
                                    </Button>
                                </div>
                            </Form>
                        </div>

                        <div className="equalSection mapSection">
                            <iframe
                                className="contactMap"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3291.0498452054967!2d150.89315481522496!3d-34.42548898050606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b1319a5fbbd09d1%3A0x2b379e32fd8cbc2!2s15C%2F157%20Crown%20St%2C%20Wollongong%20NSW%202500!5e0!3m2!1sen!2sau!4v1607832223713!5m2!1sen!2sau"
                                frameBorder="0"
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                                title="clinicMap"
                            />
                        </div>
                    </div>
                    <div className="faqCTA">
                        <h5 className="faqDescription">{Custom.faqButton.description}</h5>
                        <Button className="clickableBtn bookingBtn" size="lg" variant="primary" onClick={navigateToFaq}>
                            {Custom.faqButton.title}
                        </Button>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Enquiries;