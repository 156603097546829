export default {
    title: "Research and Publications",
    description: `
        <p>At Hashworth Psychology Pty Ltd, we balance our efforts between one-on-one psychology sessions and behind-the-scenes evidence-based-research. Scientific research is the backbone to understanding and implementing successful and effective therapy. We value the importance of keeping up to date with new research findings to discover better ways to support you.</p>
    `
    ,
    subtitle: "Published Studies",
    studies: [
        {
            individual: "Dr Talia Hashworth",
            papers: [
                `<b>Hashworth, T</b>., Reis, S., Townsend, M., O’Garr, J., & Grenyer, B. F. (2022). Personal agency and borderline personality disorder: A longitudinal study of outcomes. <i>BMC Psychiatry, 22</i>(1), 1-9. doi: https://doi.org/10.1186/s12888-022-04214-5`,
                `<b>Hashworth, T</b>., Reis, S., Grenyer, B.F.S. (2021). Personal agency in borderline personality disorder: The impact of adult attachment style. <i>Frontiers in Psychology, 12, 2224</i>. doi: https://doi.org/10.3389/fpsyg.2021.669512`,
                `Jonason, P., Fletcher, S., <b>Hashworth, T</b>. (2019). Externalizing and internalizing, but not rationalizing: Some psychodynamic biases associated with the Dark Triad traits, <i>Personality and Individual Differences, 137</i> (2019), 9-11. doi: 10.1016/j.paid.2018.08.003`,
                `Jonason, P., Fletcher, S., <b>Hashworth, T</b>. (2019). Love, sex, and personality pathology: A life history view of personality pathologies and sociosexuality. The Journal of Sex Research, 56, 239-248.`,
                `<b>Hashworth, T</b>. (2018). Personality disorders. In T.K. Shackelford & V.A. Weekes-Shackelford (Eds.), Encyclopedia of Evolutionary Psychological Science (pp. 1-13). New York, NY. Springer. doi:10.1007/978-3-319-16999-6_673-1`,
                `<b>Hashworth, T</b>. (2018). Psychopathy (Mealey). In T.K. Shackelford & V.A. Weekes-Shackelford (Eds.), Encyclopedia of Evolutionary Psychological Science (pp. 1-5). New York, NY. Springer. doi: 10.1007/978-3-319-16999-6_686-1`,
                `<b>Hashworth, T</b>., & Jonason, P.K. (2017). Antisocial behavior. In T.K. Shackelford & V.A. Weekes-Shackelford (Eds.), Encyclopedia of Evolutionary Psychological Science (pp. 1-6). New York, NY. Springer. doi: 10.1007/978-3-319-16999-6_163-2`,
                `<b>Hashworth, T</b>.(2016). Treatment Response and Treatment Failure in CBT. <i>CPA Mind Pad, 4</i> (2), 16-20.`,
                `Raymond, H., Amlung, M., De Leo, J., <b>Hashworth, T</b>., Younger, J., & MacKillop, J. (2016). Adaptation of an Acute Psychiatric Unit to a Concurrent Disorders Unit to Increase Capacity and Improve Patient Care. <i>Canadian Journal of Addiction. 7</i> (3), 5-33.`
            ]
        },
        {
            individual: "Sala Ghanem",
            papers: [
                `Shaaban, N. H., Shaaban, K. E., & <b>Ghanem, A. S.</b> (2022). The Effectiveness of the ‘DECISION MODEL’: The Impact of Self-awareness on Behavioral Sustainable Change in College Students with AD/HD. <i>Proceedings of Applied Research International Conferences</i>, 32-43.`,
            ]
        },
        {
            individual: "Olivia Green",
            papers: [
                `Bower, M., Lauria, E., <b>Green, O.</b>, ... & Teesson, M. (2023). The Social Determinants of Loneliness During COVID-19: Personal, Community, and Societal Predictors and Implications for Treatment. Behaviour Change, 40(1), 1-10.`,
                `Bower, M., Olsen, N., Peach, N., <b>Green, O.</b>, Duarte, C., Valpiani, P., & Teesson, M. (2023). Feasibility of telehealth counselling pilot for people experiencing homelessness and/or complex needs: During COVID‐19 and beyond. Health Promotion Journal of Australia.`,
                `Thornton, L., Osman, B., Champion, K., <b>Green, O.</b>, Wescott, A. B., Gardner, L. A., ... & Van de Ven, P. (2022). Measurement properties of smartphone approaches to assess diet, alcohol use, and tobacco use: systematic review. JMIR mHealth and uHealth, 10(2), e27337.`,
                `Thornton, L., Gardner, L. A., Osman, B., <b>Green, O.</b>, Champion, K. E., Bryant, Z., ... & Health4Life Team. (2021). A multiple health behavior change, self-monitoring mobile app for adolescents: development and usability study of the Health4Life app. JMIR formative research, 5(4), e25513.`,
            ]
        }
    ]
}